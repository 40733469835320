<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{$t('creator.titleDemographics')}}</h1>
        <p>{{$t('creator.dataBenefit')}}</p>
      </b-col>

      <b-col class="col-12 mt-3">
        <v-select
          v-model="gender"
          label="text"
          :options="$t('creator.gender')"
          :clearable="false"
        />
      </b-col>

      <b-col class="col-12 mt-3">
        <v-select
          v-model="scholarship"
          label="text"
          :options="$t('creator.scholarship')"
          :clearable="false"
        />
      </b-col>

      <b-col class="col-12 mt-3">
        <div class="d-flex align-items-center">
          <h3 class="m-0">{{$t('creator.titleEthnicity')}}</h3>
          <b-badge variant="color-badge" pill class="color-badge ml-1">{{$t('creator.alertMaximum')}} 4</b-badge>
        </div>
        
        <b-row class="mr-1 ml-0 mt-1" :key="ready_response">
          <b-button
            v-for="(ethnicity, index) in $t('creator.ethnicity')"
            :key="index"
            :variant="isOptionSelected(ethnicity)[0]"
            :class="isOptionSelected(ethnicity)[0]"
            pill
            @click="addOrRemoveOption(ethnicity)">
            <b-icon :icon="isOptionSelected(ethnicity)[1]"></b-icon>
            {{ ethnicity.text}}
          </b-button>
        </b-row>
        <div v-if="ethnicities_selected.length > 3" class="alert alert-warning msj-warning" role="alert">
          <div>
          <feather-icon icon="InfoIcon" class="mr-1" size="20"/> <span>No puedes tener más de 4 etnias</span>
          </div> 
        </div>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{ getTextButton() }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBadge
} from 'bootstrap-vue';
import vSelect from 'vue-select'
import utils from '@/libs/utils';
import others from '@/services/others'
import abilities from '@/libs/acl/abilities';

export default {
  name: 'PublicProfilDemographics',
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
    vSelect
  },
  data() {
    return {
      utils,
      gender: this.$t('creator.gender')[0],
      scholarship: this.$t('creator.scholarship')[0],
      ethnicities_selected: [],
      ready_response: false,
      has_ethnicity:false,
      interval_loading: null,
      editing: this.$route.params.is_edit ? this.$route.params.is_edit : ''
    }
  },
  created() {
    this.getProfile()
  },
  methods: {
    getProfile () {
      others.getProfile().then(response => {
        const user = response.user
        if (user.meta_info) {
          if (user.meta_info.gender) {
            const exists_gender = this.$t('creator.gender').find(gen => gen.value === user.meta_info.gender)
            this.gender = exists_gender ? exists_gender : {value: 'prefer_not_say', text: 'Prefiero no decir'}
          }

          if (user.meta_info.scholarship) {
            const exists_scholarship = this.$t('creator.scholarship').find(gescholarship => gescholarship.value === user.meta_info.scholarship)
            this.scholarship = exists_scholarship ? exists_scholarship : this.$t('creator.scholarship')[0]
          }

          if (user.meta_info.ethnicity && user.meta_info.ethnicity.length > 0) {
            this.has_ethnicity = true
            user.meta_info.ethnicity.forEach(ethn => {
              const ethnicity_exists = this.$t('creator.ethnicity').find(ethnicity => ethnicity.value === ethn)
              if (ethnicity_exists) {
                this.ethnicities_selected.push(ethnicity_exists)
              }
            })
          }
        }
      })
    },
    getTextButton() {
      if (this.editing) return this.$t('dashboard.save')
      else return this.$t('creator.butonNext')
    },
    isOptionSelected(option) {
      const exists_option = this.ethnicities_selected.find(opt => option.value === opt.value)
      return exists_option !== undefined ? ['class-btn-check', 'check2'] : ['class-btn-plus', 'plus'];
    },
    addOrRemoveOption(option) {
      if (this.ethnicities_selected.find((ethn) => option.value === ethn.value) !== undefined) {
        this.ethnicities_selected = this.ethnicities_selected.filter((ethn) => ethn !== option);
      } else if (this.ethnicities_selected.length < 4) {
        this.ethnicities_selected.push(option)
      }
    },
    save() {
      const format_ethnicity = []
      this.ethnicities_selected.forEach(ethn => {
        format_ethnicity.push(ethn.value)
      });

      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);

      const data = {
        gender: this.gender.value,
        scholarship: this.scholarship.value,
        ethnicity: format_ethnicity,
        empty_ethnicity: format_ethnicity.length === 0
      }


      others.updateProfile(data).then(response => {
        utils.setPermissions(response.user);
        this.readAbility = abilities.getAbilitie(response.user) 
        
        utils.setuserData(response, this.readAbility)

        if (this.editing) {   
          this.$router.push({name: 'my_work', params: {section: 'networks'}});
          this.$root.$emit('quit_navbar_white');
        } else {
          this.$router.push({ name: 'setting-public-profile', params: {section: 'family'}})
        }

        clearInterval(this.interval_loading)
        this.$vs.loading.close()
      })
        .catch(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
    },
  },
}
</script>
<style>
.class-btn-plus {
  margin: 7px 4px 0px 0px;
  padding: 5px 13px 5px 13px;
  background-color: #fff !important;
  border-color: #ced4da !important;
  color: #6c757d !important;
}
.class-btn-check {
  margin: 7px 4px 0px 0px;
  padding: 5px 13px 5px 13px;
  background-color: #d5ebe4 !important;
  border-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.msj-warning {
  margin-top: 10px;
  padding: 10px;
  font-size: small;
}
.color-badge {
  background: #FCD147;
  color: black;
  font-size: 75%;
}
</style>