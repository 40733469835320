<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{$t('creator.titleTellFamily')}}</h1>
        <p>{{$t('creator.textUtilFamily')}}</p>
      </b-col>

      <b-col class="col-12 mt-3">
        <v-select
          v-model="relationship"
          label="text"
          :options="$t('creator.relationship')"
          :clearable="false"
        />
      </b-col>

      <b-col class="col-12 mt-3">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="m-0">{{$t('creator.titleChildren')}}</h3>
          <b-link @click="addChild">{{$t('creator.add_Child')}}</b-link>
        </div>

        <b-col class="col-12 mt-3 p-0" :key="`children_${ready_children}`">
          <div class="mt-4" v-for="(child, index) in children" :key="index">
            <b-row>
              <b-col class="col-12">
                <b-row>
                  <b-col class="col-11">
                    <v-select
                      :value="getValueGender(child)"
                      label="text"
                      :options="$t('creator.gender_child')"
                      @input="(new_gender) => changeGender(index, new_gender)"
                    />
                  </b-col>

                  <b-col class="col-1 d-flex justiify-content-center align-items-center">
                    <feather-icon
                      class="cursor-pointer"
                      icon="Trash2Icon"
                      size="18"
                      @click="deleteChild(index)"
                    />
                  </b-col>
                </b-row>
              </b-col>

              <b-col class="col-12 mt-1">
                <v-select
                  :value="getValueAge(child)"
                  label="text"
                  :options="$t('creator.age_range')"
                  @input="(new_age) => changeAge(index, new_age)"
                />
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-col>

      <b-col class="col-12 mt-3">
        <div class="d-flex align-items-center">
          <h3 class="m-0">{{$t('creator.titlePet')}}</h3>
          <b-badge variant="color-badge" pill class="color-badge ml-1">{{$t('creator.alertMaximum')}} 4</b-badge>
        </div>
        
        <b-row class="mr-1 ml-0 mt-1" :key="`pets_${ready_response}`">
          <b-button
            v-for="(pet, index) in $t('creator.pets')"
            :key="index"
            :variant="isOptionSelected(pet)[0]"
            :class="isOptionSelected(pet)[0]"
            pill
            @click="addOrRemoveOption(pet)">
            <b-icon :icon="isOptionSelected(pet)[1]"></b-icon>
            {{ pet.text}}
          </b-button>
        </b-row>
        <div v-if="pets_selected.length > 3" class="alert alert-warning msj-warning" role="alert">
          <div>
          <feather-icon icon="InfoIcon" class="mr-1" size="20"/> <span>No puedes tener más de 4 etnias</span>
          </div> 
        </div>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{ getTextButton() }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BLink
} from 'bootstrap-vue';
import vSelect from 'vue-select'
import utils from '@/libs/utils';
import others from '@/services/others'
import abilities from '@/libs/acl/abilities';

export default {
  name: 'PublicProfileFamily',
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,
    vSelect
  },
  data() {
    return {
      utils,
      ready_response: 0,
      ready_children: 0,
      relationship: this.$t('creator.relationship')[3],
      pets_selected: [],
      children: [{}],
      has_vanity: false,
      interval_loading: null,
      editing: this.$route.params.is_edit ? this.$route.params.is_edit : ''
    }
  },
  created() {
    this.getProfile()
  },
  destroyed() {
    if (this.has_vanity) {
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    }
  },
  methods: {
    getProfile () {
      others.getProfile().then(response => {
        const user = response.user

        if (user.meta_info) {

          if (user.meta_info && user.meta_info.vanity_name) this.has_vanity = true
          if (user.meta_info.relationship) {
            const exists_relationship = this.$t('creator.relationship').find(scholar => scholar.value === user.meta_info.relationship)
            this.relationship = exists_relationship ? exists_relationship : {value: 'prefer_not_say', text: 'Prefiero no decir'}
          }

          if (user.meta_info.pets && user.meta_info.pets.length > 0) {
            user.meta_info.pets.forEach(pet_user => {
              const pet_exists = this.$t('creator.pets').find(pet => pet.value === pet_user)
              if (pet_exists) {
                this.pets_selected.push(pet_exists)
              }
            })
          }

          if (user.meta_info.children.length > 0) {
            this.children = user.meta_info.children
          }
        }

        this.ready_response += 1
        this.ready_children += 1
      })
    },
    getTextButton() {
      if (this.editing) return this.$t('dashboard.save')
      else return this.$t('creator.butonNext')
    },
    isOptionSelected(option) {
      const exists_option = this.pets_selected.find(opt => option.value === opt.value)
      return exists_option !== undefined ? ['class-btn-check', 'check2'] : ['class-btn-plus', 'plus'];
    },
    addOrRemoveOption(option) {
      if (this.pets_selected.find((pet) => option.value === pet.value) !== undefined) {
        this.pets_selected = this.pets_selected.filter((pet) => pet !== option);
      } else if (this.pets_selected.length < 4) {
        this.pets_selected.push(option)
      }
    },
    getValueGender(child) {
      if (child.gender !== undefined && child.gender !== '') {
        const exists_gender = this.$t('creator.gender').find(gen => gen.value === child.gender)
        if (exists_gender) return exists_gender
      }

      return ''
    },
    getValueAge(child) {
      if (child.age_range !== undefined && child.age_range !== '') {
        const exists_age = this.$t('creator.age_range').find(age => age.value === child.age_range)
        if (exists_age) return exists_age
      }
      return ''
    },
    changeGender(index, new_gender) {
      this.children[index].gender = new_gender ? new_gender.value : {}
      this.ready_children += 1
    },
    changeAge(index, new_age) {
      this.children[index].age_range = new_age ? new_age.value : {}
      this.ready_children += 1
    },
    addChild() {
      this.children.push({})
    },
    deleteChild(index) {
      this.children.splice(index, 1)
      this.ready_children += 1
    },
    save() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      const format_pets = []
      this.pets_selected.forEach(pet => {
        format_pets.push(pet.value)
      });

      const format_children = []

      this.children.forEach(child => {
        if (child.gender && child.age_range) format_children.push(child)
      });

      const data = {
        relationship: this.relationship.value,
        pets: format_pets,
        empty_pets: format_pets.length === 0,
        children: format_children,
        empty_children: format_children.length === 0
      }


      others.updateProfile(data).then(response => {
        utils.setPermissions(response.user);
        this.readAbility = abilities.getAbilitie(response.user) 
        
        utils.setuserData(response, this.readAbility)

        if (this.editing) {
          this.$router.push({name: 'my_work', params: {section: 'networks'}});
          this.$root.$emit('quit_navbar_white');
        } else {
          this.$router.push({ name: 'setting-public-profile', params: {section: 'vanity'}})
        }

        clearInterval(this.interval_loading)
        this.$vs.loading.close()
      })
        .catch(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
    },
  },
}
</script>
<style>
.class-btn-plus {
  margin: 7px 4px 0px 0px;
  padding: 5px 13px 5px 13px;
  background-color: #fff !important;
  border-color: #ced4da !important;
  color: #6c757d !important;
}
.class-btn-check {
  margin: 7px 4px 0px 0px;
  padding: 5px 13px 5px 13px;
  background-color: #d5ebe4 !important;
  border-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.msj-warning {
  margin-top: 10px;
  padding: 10px;
  font-size: small;
}
.color-badge {
  background: #FCD147;
  color: black;
  font-size: 75%;
}
</style>